[data-theme='dark'] .katex-display,
[data-theme='dark'] .katex {
  color: white !important;
}

[data-theme='light'] .katex-display,
[data-theme='light'] .katex {
  color: #000000 !important; /* Adjust this color to your preferred dark shade */
}

[data-theme='dark'] .katex-display,
[data-theme='dark'] .katex {
  color: white !important;
}


