body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700&display=swap');

:root {
  /* Font & Line-Height */
  --font-family: "JetBrains Mono", monospace;
  --line-height: 1.5rem;

  /* Font Weights */
  --font-weight-normal: 700; /* Increased to make text slightly bolder */
  --font-weight-bold: 700;

  /* Light Mode Colors */
  --text-color-light: #463730; /* Dark brown */
  --background-color-light: #f5f5dc; /* Beige */
  --link-color-light: #463730; /* Dark brown */
  --link-hover-color-light: #008080; /* Teal */

  /* Dark Mode Colors */
  --text-color-dark: #ffffff; /* White */
  --background-color-dark: #000000; /* Black */
  --link-color-dark: #ffffff; /* White for links */
  --link-hover-color-dark: #94a3b8; /* Slate (hover color in dark mode) */
}

body {
  margin: 0;
  font-family: var(--font-family);
  font-weight: var(--font-weight-normal); /* Using the increased weight */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: var(--line-height);
}

/* Font weight for bold text */
.font-bold {
  font-weight: var(--font-weight-bold) !important;
}

/* Light Mode Styling */
body {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

/* Dark Mode Styling */
body.dark {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

/* Global Link Styling (Light Mode by Default) */
a {
  color: var(--link-color-light);
  font-family: var(--font-family);
  text-decoration: underline;
  transition: color 0.3s ease;
}

/* Link Hover Effect in Light Mode */
a:hover {
  color: var(--link-hover-color-light); /* Teal hover */
}

/* Dark Mode Link Styling */
body.dark a {
  color: var(--link-color-dark); /* White in dark mode */
}

/* Dark Mode Link Hover */
body.dark a:hover {
  color: var(--link-hover-color-dark); /* Slate (grayish-blue) hover */
}

/* Accessibility Fix for Links in Focus or Active State */
a:focus,
a:active {
  outline: none; /* Remove focus or active styles */
}

/* Tailwind Utility Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;
